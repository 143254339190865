<template>
	<div>
		<div class="d-flex justify-center mb-3">
			<div class="temp-chart"></div>
			<div class="hump-chart"></div>
		</div>

		<v-img src="../assets/img/sensor.png" height="400" contain />
	</div>
</template>

<script>
import httpClient from "@/httpClient";

export default {
	name: "SensorOnlineChartComponent",
	data: () => ({
		interval: null
	}),
	destroyed() {
		clearInterval(this.interval);
	},
	mounted() {
		google.charts.load("current", { packages: ["gauge"] });
		google.charts.setOnLoadCallback(async () => {
			const loadChartData = async (firstChatData, secondChartData) => {
				const response = (
					await httpClient().get(`/sensor/lastActualData?deviceId=1`)
				).data[0];

				const convertToNumber = num => parseFloat(num.replace(",", "."));

				// Температура
				const temp = convertToNumber(response.temp);

				// Влажность
				const hump = convertToNumber(response.hump);

				firstChatData.setValue(0, 1, temp);
				secondChartData.setValue(0, 1, hump);
			};

			const firstChartData = google.visualization.arrayToDataTable([
				["Label", "Value"],
				["Температура", 0]
			]);

			const secondChartData = google.visualization.arrayToDataTable([
				["Label", "Value"],
				["Влажность", 0]
			]);

			await loadChartData(firstChartData, secondChartData);

			const firstChartOptions = {
				width: 400,
				height: 120,
				minorTicks: 5,
				min: -20,
				max: 50
			};

			const secondChartOptions = {
				width: 400,
				height: 120,
				minorTicks: 5,
				min: 0,
				max: 100
			};

			const chart = new google.visualization.Gauge(document.querySelector(".temp-chart"));
			const chart2 = new google.visualization.Gauge(document.querySelector(".hump-chart"));

			chart.draw(firstChartData, firstChartOptions);
			chart2.draw(secondChartData, secondChartOptions);

			this.interval = setInterval(async () => {
				await loadChartData(firstChartData, secondChartData);
				chart.draw(firstChartData, firstChartOptions);
				chart2.draw(secondChartData, secondChartOptions);
			}, 500);
		});
	}
};
</script>

<style scoped></style>

<template>
	<div>
		<div class="d-flex justify-center mb-3">
			<div class="first-chart"></div>
			<div class="second-chart"></div>
		</div>

		<v-img src="../assets/img/motor.png" height="400" contain />
	</div>
</template>

<script>
import httpClient from "@/httpClient";

export default {
	name: "MotorOnlineChartComponent",
	data: () => ({
		interval: null
	}),
	destroyed() {
		clearInterval(this.interval);
	},
	mounted() {
		google.charts.load("current", { packages: ["gauge"] });
		google.charts.setOnLoadCallback(async () => {
			const loadChartData = async (firstChatData, secondChartData) => {
				const response = (
					await httpClient().get(`/servodrive/lastActualData?servoDriveId=1`)
				).data[0];

				const convertToNumber = num => parseFloat(num.replace(",", "."));

				// Ток фаза U
				const actPhaseU = convertToNumber(response.actPhaseU);

				// Ток фаза V
				const actPhaseV = convertToNumber(response.actPhaseV);

				// Ток
				const actOutCurrent = convertToNumber(response.actOutCurrent);

				// Момент
				const actVelocity = convertToNumber(response.actVelocity);

				firstChatData.setValue(0, 1, actPhaseU);
				firstChatData.setValue(1, 1, actPhaseV);
				firstChatData.setValue(2, 1, actOutCurrent);

				secondChartData.setValue(0, 1, actVelocity);
			};

			const firstChartData = google.visualization.arrayToDataTable([
				["Label", "Value"],
				["Ток фаза U", 0],
				["Ток фаза V", 0],
				["Ток", 0]
			]);

			const secondChartData = google.visualization.arrayToDataTable([
				["Label", "Value"],
				["Момент", 0]
			]);

			await loadChartData(firstChartData, secondChartData);

			const firstChartOptions = {
				width: 400,
				height: 120,
				minorTicks: 5,
				min: -2,
				max: 2
			};

			const secondChartOptions = {
				width: 400,
				height: 120,
				minorTicks: 5,
				min: -40,
				max: 40
			};

			const chart = new google.visualization.Gauge(document.querySelector(".first-chart"));
			const chart2 = new google.visualization.Gauge(document.querySelector(".second-chart"));

			chart.draw(firstChartData, firstChartOptions);
			chart2.draw(secondChartData, secondChartOptions);

			this.interval = setInterval(async () => {
				await loadChartData(firstChartData, secondChartData);
				chart.draw(firstChartData, firstChartOptions);
				chart2.draw(secondChartData, secondChartOptions);
			}, 500);
		});
	}
};
</script>

<style scoped></style>

<template>
	<div class="online-sensors wrapper">
		<v-row>
			<v-col>
				<MotorOnlineChartComponent />
			</v-col>

			<v-col>
				<SensorOnlineChartComponent />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import MotorOnlineChartComponent from "@/components/MotorOnlineChartComponent";
import SensorOnlineChartComponent from "@/components/SensorOnlineChartComponent";

export default {
	name: "OnlineSensorPage",
	components: { SensorOnlineChartComponent, MotorOnlineChartComponent },

};
</script>

<style scoped lang="scss">
.online-sensors {
	margin-top: 35px;
}
</style>
